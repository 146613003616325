.submitButton {
  align-self: flex-end;
  padding-right: 56px !important;
  padding-left: 56px !important;
}

.errorField {
  color: var(--mantine-color-error);
}

.logo {
  height: 124px;
  width: 124px !important;
}

.successSection {
  margin: 0 auto;
  width: 100%;
  height: 100vh;
  max-width: 745px;
  padding: 0 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.userData {
  @media (max-width: 48em) {
    flex-direction: column;
  }
}

.modalOverlay {
  background: var(--overlay-bg, rgba(255, 255, 255, 0.6)) !important;
}

.selectDropdown {
  color: black !important;
}

.page {
  padding: 24px;
  width: 100%;
}
.header {
  border-bottom: 1px solid #1f1f1f;
}

.listItem {
  display: flex;
  justify-content: space-between;
}
