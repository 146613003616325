.submitButton {
  align-self: flex-end;
}

.errorField {
  color: var(--mantine-color-error);
}

.modal {
  width: 600px !important;
}

.modalOverlay {
  background: var(--overlay-bg, rgba(255, 255, 255, 0.6)) !important;
}

.listItem {
  display: flex;
  justify-content: space-between;
}

.levelsGroup {
  color: black !important;
}

.pageContent {
  background: #0b0b0b;
  border-radius: 18px;
  margin-top: 30px;
  padding: 24px;
}
.commentWrap {
  flex-direction: column;
  margin-bottom: 40px !important;
  padding-left: 50px !important;
  position: relative !important;
}
.avatar {
  position: absolute;
  left: 0;
  top: 0;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50px !important;
}

.multiselectOption {
  color: var(--mantine-color-black) !important;
}

.searchInput {
  padding: 4px;
}

.comment {
  font-weight: 500;
}
.commentAuthor {
  color: var(--electric-lime-color);
  font-weight: 500;
}
.commentDate {
  color: var(--shark-body-text-color);
  font-weight: 500;
}
.errorField {
  color: var(--mantine-color-error) !important;
}

.file {
  cursor: pointer;
  transition: ease 300ms;
}
.file:hover {
  opacity: 0.8;
}

.courseNameBlock {
  align-items: center;
  @media (max-width: 48em) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.addButton {
  margin: 0 16px 0 0;
  @media (max-width: 48em) {
    margin: 0 0 12px 0 !important;
  }
}

.fieldData {
  @media (max-width: 48em) {
    flex-direction: column;
  }
}
.roleField {
  justify-content: center;
  @media (max-width: 48em) {
    justify-content: flex-start;
  }
}
.eventField {
  width: 33%;
  @media (max-width: 48em) {
    width: 90%;
  }
}
