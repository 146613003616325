.submitButton {
  align-self: flex-end;
  padding-right: 56px !important;
  padding-left: 56px !important;
}

.forgotPasswordButton {
  font-weight: 700;
  cursor: pointer !important;
}

.errorField {
  color: var(--mantine-color-error);
}
