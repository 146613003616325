.image {
  height: 50px !important;
  width: 50px !important;
  border-radius: 50% !important;
  cursor: pointer;
  flex: 0 1 50px !important;
}

.dropdown {
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #2f2f2f !important;
  background: #0b0b0b !important;
  color: #9c9ca4;
  font-size: 16px;
  padding: 19px 18px;
  cursor: pointer;
}
