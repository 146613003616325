.header {
  border-bottom: 1px solid #1f1f1f;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 64em) {
    flex-direction: column;
    align-items: normal;
  }
}

.resourcesButton {
  @media (max-width: 64em) {
    align-self: flex-start;
    margin-bottom: 16px;
  }
}

.createCourseBlockForm {
  @media (max-width: 64em) {
    align-self: flex-end;
  }
}
.createCourseBlockForm {
  @media (max-width: 30em) {
    width: 100% !important;
  }
}
.createCourseBlockFormContent {
  @media (max-width: 30em) {
    flex-direction: column;
  }
}
.blockNameInput {
  width: 300px;
  @media (max-width: 48em) {
    width: 250px;
  }
}
.blockNameInput {
  @media (max-width: 30em) {
    margin-bottom: 16px;
    width: 100% !important;
  }
}
.createBlockButton {
  @media (max-width: 30em) {
    margin-bottom: 16px;
    width: 200px;
    align-self: flex-end;
  }
}

.modalOverlay {
  background: var(--overlay-bg, rgba(255, 255, 255, 0.6)) !important;
}

.listItem {
  display: flex;
  justify-content: space-between;
}

.levelsGroup {
  color: black !important;
}

.pageContent {
  background: #0b0b0b;
  border-radius: 18px;
  margin-top: 30px;
  padding: 24px;
  display: flex;
  flex-direction: column;
}

.multiselectOption {
  color: var(--mantine-color-black) !important;
}

.searchInput {
  padding: 4px;
}

.errorField {
  color: var(--mantine-color-error);
}

.lesson {
  width: 100%;
  border-radius: 12px;
  background: #141414;
  padding: 24px 20px;
  justify-content: space-between;
  align-items: center;
}
.lessons {
  display: flex;
  flex-direction: column;
}

.editInput {
  width: 200px;
  margin-right: 20px;
  @media (max-width: 30em) {
    width: 120px;
    margin-right: 10px;
  }
}
