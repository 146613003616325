.input {
  font-size: 12px !important;
}

.label {
  font-size: 14px !important;
  font-weight: 400 !important;
  margin-bottom: 5px;
}

.blackInput {
  background-color: #141414 !important;
  color: var(--mantine-color-white) !important;
}
.blackWrapper path {
  fill: var(--mantine-color-white) !important;
}
