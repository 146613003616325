.navbar {
  background-color: light-dark(
    var(--mantine-color-white),
    var(--mantine-color-dark-6)
  );
  height: rem(800px);
  width: rem(300px);
  padding: var(--mantine-spacing-md);
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  border-right: rem(1px) solid
    light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
}

.header {
  padding: var(--mantine-spacing-md);
  padding-top: 0;
  margin-left: calc(var(--mantine-spacing-md) * -1);
  margin-right: calc(var(--mantine-spacing-md) * -1);
  color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
  border-bottom: rem(1px) solid
    light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
}

.links {
  flex: 1;
  margin-left: calc(var(--mantine-spacing-md) * -1);
  margin-right: calc(var(--mantine-spacing-md) * -1);
}

.linksInner {
  padding-top: var(--mantine-spacing-xl);
  padding-bottom: var(--mantine-spacing-xl);
}

.layout {
  @media (max-width: 48em) {
    flex-direction: column;
  }
}
