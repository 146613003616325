.listItem {
  padding: 20px;
  background: #090909;
  border-radius: 12px;
}

.listItemContainer {
  padding: 1px;
  margin-bottom: 12px;
  border-radius: 12px;
}

.listItemOutlined {
  background: linear-gradient(0deg, rgba(22, 22, 22, 1), rgba(55, 55, 55, 1));
}
