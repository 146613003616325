.pageContent {
  background: #0b0b0b;
  border-radius: 18px;
  max-width: calc(100vw - 250px);
  margin-top: 30px;
  padding: 24px;
  display: flex;
  height: 100%;
  @media (max-width: 48em) {
    max-width: 100vw;
    flex-direction: column;
  }
}

.lessonNav {
  display: flex;
  flex-direction: column;
  padding-right: 20px;
  flex: 0 0 220px;
  @media (max-width: 48em) {
    padding-right: 0px;
    margin-bottom: 24px;
  }
}

.navItem {
  height: 52px;
  margin-bottom: 12px;
  font-weight: 500;
  font-size: 14px;
  border-radius: 6px;
  background: #141414;
  color: var(--mantine-color-white);
  padding-left: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.navItem.active {
  background: var(--electric-lime-color);
  color: var(--mantine-color-black);
}

.lessonContent {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: #141414;
  padding: 32px;
}
