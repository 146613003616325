.page {
  padding-top: 24px;
  width: 100%;
}
.header {
  border-bottom: 1px solid #1f1f1f;
}
.backToCourseButton {
  font-size: 14px;
  font-weight: normal;
  color: #9c9ca4 !important;
  height: 64px !important;
  border-radius: 12px;
  background: #0b0b0b !important;
}
