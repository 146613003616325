.page {
  min-height: 100vh;
  width: 100vw;
  background-color: var(--mantine-color-black);
  display: flex;
}

.formContainer {
  padding: 77px 20px;
  margin: auto;
}

.backgroundImage {
  width: 50% !important;
  display: flex !important;
  flex-direction: column;
  justify-content: flex-end !important;
}

.submitButton {
  align-self: flex-end;
  padding-right: 56px !important;
  padding-left: 56px !important;
}

.logo {
  height: 124px;
  width: 124px !important;
}

.text {
  color: #c8c8c8 !important;
}
